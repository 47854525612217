<template>
  <div class="container-fluid">
    <md-card>
      <md-card-header :data-background-color="getTheme">
        <h4 class="title">{{ title }}</h4>
        <p class="category">{{ category }}</p>
      </md-card-header>

      <md-card-content>
        <div class="row">
          <div
            v-for="input in inputs"
            :key="input.name"
            :class="input.class"
            class="col-md-4 col-sm-6 col-12"
          >
            <div class="col" v-if="input.type == 'text'">
              <div class="row d-flex align-center m-0">
                <div class="col col-10">
                  <v-text-field
                    :label="$t(input.label)"
                    hide-details="auto"
                    :placeholder="input.placeholder"
                    v-model="input.value"
                    :hint="$t(input.hint)"
                    :rules="setRules(input.rules)"
                    :formatters="inputs.formaters"
                    :disabled="input.disabled"
                    @input="setResponse"
                    
                  ></v-text-field>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="m-0 p-0 col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
                </v-tooltip>
              </div>
              <div
                class="row d-flex align-center mt-5 justify-content-around"
                v-if="input.name == 'icon'"
              >
                <span>Suggestions</span>
                <div v-for="icon in defaultFill.icons" :key="icon">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        size="20"
                        class="iconPointer"
                        v-bind="attrs"
                        v-on="on"
                        @click="input.value = icon"
                        >{{ icon }}</v-icon
                      >
                    </template>
                    <span>{{ icon }}</span>
                  </v-tooltip>
                </div>
                <a href="https://materialdesignicons.com/" target="_blank">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" size="20" v-bind="attrs" v-on="on"
                        >mdi-open-in-new</v-icon
                      >
                    </template>
                    <span>Find more icons here</span>
                  </v-tooltip></a
                >
              </div>
            </div>
            <div
              class="row d-flex align-center m-0"
              v-if="input.type == 'textarea'"
            >
              <div class="col col-10">
                <v-textarea
                  :label="$t(input.label)"
                  hide-details="auto"
                  :placeholder="input.placeholder"
                  v-model="input.value"
                  :hint="$t(input.hint)"
                  :rules="setRules(input.rules)"
                  :formatters="inputs.formaters"
                  :disabled="input.disabled"
                  @intput="setResponse"
                ></v-textarea>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
            <div
              class="row d-flex align-center m-0"
              v-if="input.type == 'select'"
            >
              <div class="col col-10">
                <v-select
                  :label="$t(input.label)"
                  hide-details="auto"
                  :placeholder="input.placeholder"
                  v-model="input.value"
                  :items="input.options"
                  item-text="label"
                  item-value="value"
                  :hint="$t(input.hint)"
                  :rules="setRules(input.rules)"
                  :formatters="inputs.formaters"
                  :disabled="input.disabled"
                  @input="setResponse"
                >
                </v-select>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
            <div
              class="row d-flex align-center m-0"
              v-if="input.type == 'autocomplete'"
            >
              <div class="col col-10">
                <v-autocomplete
                  :label="$t(input.label)"
                  hide-details="auto"
                  :placeholder="input.placeholder"
                  v-model="input.value"
                  :items="input.options"
                  item-text="label"
                  item-value="value"
                  :hint="$t(input.hint)"
                  :rules="setRules(input.rules)"
                  :formatters="inputs.formaters"
                  :disabled="input.disabled"
                  @input="setResponse"
                >
                </v-autocomplete>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
            <div
              class="row d-flex align-center m-0"
              v-if="input.type == 'radio'"
            >
              <div class="col col-10">
                <v-radio-group
                  :label="$t(input.label)"
                  hide-details="auto"
                  v-model="input.value"
                  :hint="$t(input.hint)"
                  :rules="setRules(input.rules)"
                  :formatters="inputs.formaters"
                  :disabled="input.disabled"
                  @change="setResponse"
                >
                  <v-radio
                    v-for="option in input.options"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  ></v-radio>
                </v-radio-group>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
            <div
              class="row d-flex align-center m-0"
              v-if="input.type == 'checkbox'"
            >
              <div class="col col-10">
                <v-checkbox
                  v-for="option in input.options"
                  :key="option.label"
                  v-model="input.value"
                  :label="option.label"
                  :value="option.value"
                  @change="setResponse"
                ></v-checkbox>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
            <div
              class="row d-flex align-center m-0"
              v-if="input.type == 'combobox'"
            >
              <div class="col col-10">
                <v-combobox
                  :label="$t(input.label)"
                  hide-details="auto"
                  :placeholder="input.placeholder"
                  v-model="input.value"
                  :items="input.options"
                  :hint="$t(input.hint)"
                  :rules="setRules(input.rules)"
                  :formatters="inputs.formaters"
                  :disabled="input.disabled"
                  @change="setResponse"
                  @input="setResponse"
                ></v-combobox>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
            <div
              class="row d-flex align-center m-0"
              v-if="input.type == 'switch'"
            >
              <div class="col col-10">
                <v-switch
                  v-model="input.value"
                  :label="$t(input.label)"
                  @change="setResponse"
                ></v-switch>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
            <div
              class="row d-flex align-center m-0 justify-space-between"
              v-if="input.type == 'color'"
            >
              <div class="col col-10 d-flex justify-space-around">
                <label for="">{{ $t(input.label) }}</label>
                <input type="color" v-model="input.value" name="color" />
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="m-0 p-0 col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t(`tooltips.${splitLabel(input.label)}`) }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import form from "@/mixins/form";
export default {
  name: "DynamicForm",
  props: {
    inputs: {
      type: Array,
      required: true,
    },
    sendData: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    defaultFill: {
      type: Object,
      required: true,
    },
  },
  mixins: [form],
  watch: {
    sendData() {
      this.setResponse();
    },
    "defaultFill.title"() {
      this.$forceUpdate();
      this.setDefault();
    },
    "defaultFill.icon"() {
      this.$forceUpdate();
      this.setDefault();
    },
    "defaultFill.symbol"() {
      this.$forceUpdate();
      this.setDefault();
    },
    "defaultFill.icons"() {
      this.$forceUpdate();
      this.setDefault();
    },
    inputs() {
      this.setDefault();
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  data() {
    return {
      response: {},
    };
  },
  methods: {
    setResponse() {
      this.inputs.forEach((input) => {
        this.response[input.name] =
          input.value === null ? input.defaultValue : input.value;
      });
      this.$emit("response", this.response);
    },
    splitLabel(label) {
      return label.substring(label.lastIndexOf(".") + 1);
    },
    setDefault() {
      this.inputs.forEach((input) => {
        if (input.name == "title") {
          input.value = this.defaultFill.title;
        }
        if (input.name == "symbol") {
          input.value = this.defaultFill.symbol;
        }
        if (input.name == "icon") {
          input.value = this.defaultFill.icon;
        }
      });
      this.setResponse();
    },
    setRules(inputRules) {
      return inputRules ? inputRules.map((item) => this.rules[item]) : [];
    },
  },
  mounted() {
    this.setDefault();
  },
};
</script>
<style  scoped>
.iconPointer {
  cursor: pointer;
}
</style>