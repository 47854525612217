<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <tgl-breadcrumbs :items="breadcrumbs" />
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <slot-form :data-background-color="getTheme" />
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "../../components/UI/TglBreadcrumbs";
import SlotForm from "../../components/Slots/SlotForm";
import { mapGetters } from "vuex";
export default {
  components: {
    SlotForm,
    TglBreadcrumbs,
  },
  data() {
    return {
      device: null,
    };
  },
  computed: {
    ...mapGetters(["getTheme"]),
    breadcrumbs() {
      return [
        {
          label: "Dispositivos",
          disabled: false,
          to: { name: "devices" },
        },
        {
          label: this.device ? this.device.alias : this.$route.params.device,
          disabled: false,
          to: {
            name: "deviceDetails",
            params: { device: this.$route.params.device },
          },
        },
        {
          label: "Slots",
          disabled: false,
          to: {
            name: "deviceSlots",
            params: { device: this.$route.params.device },
          },
        },
        {
          label: "Creando slot",
          disabled: true,
          to: {
            name: "slotCreate",
            params: {
              device: this.$route.params.device,
            },
          },
        },
      ];
    },
  },
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
        });
    },
  },
  mounted() {
    this.getDevice();
  },
};
</script>