<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title" v-text="$t('device.slot.slotForm.titleFormCreate')"></h4>
    </md-card-header>

    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField :label="$t('device.slot.slotForm.alias')" v-model="form.alias" :rules="[
                    rules.required,
                    (value) => rules.minLength(value, 0),
                    (value) => rules.maxLength(value, 100),
                  ]" />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" size="20" class="col col-2" v-bind="attrs" v-on="on">
                      mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("tooltips.slotForm.alias") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField :label="$t('device.slot.slotForm.parameterName')" v-model="form.parameterName" :rules="[
                    rules.required,
                    (value) => rules.minLength(value, 0),
                    (value) => rules.maxLength(value, 100),
                  ]" />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" size="20" class="col col-2" v-bind="attrs" v-on="on">
                      mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("tooltips.slotForm.parameterName") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select item-value="id" item-text="name" :items="variables"
                    :label="$t('device.slot.slotForm.variable')" :rules="[rules.required]" v-model="form.variableId">
                    <template slot="item" slot-scope="data">
                      <template>
                        <span>
                          <v-icon color="primary" class="mr-2">{{
                            data.item.icons[0]
                          }}</v-icon>
                          {{ data.item.name }}</span>
                      </template>
                    </template>
                    <template slot="selection" slot-scope="data">
                      <template>
                        <span>
                          <v-icon color="primary" class="mr-2">{{
                            data.item.icons[0]
                          }}</v-icon>
                          {{ data.item.name }}</span>
                      </template>
                    </template>
                  </v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" size="20" class="col col-2" v-bind="attrs" v-on="on">
                      mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("tooltips.slotForm.variable") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col 12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select item-value="id" item-text="name" :items="variableUnitOfMeasurement"
                    :label="$t('device.slot.slotForm.unitOfMeasurement')" :rules="[rules.required]"
                    v-model="form.unitOfMeasurementId">
                    <template slot="item" slot-scope="data">
                      <template>
                        <span>
                          {{ data.item.name }} - {{ data.item.symbol }}</span>
                      </template>
                    </template>
                    <template slot="selection" slot-scope="data">
                      <template>
                        <span>
                          {{ data.item.name }} - {{ data.item.symbol }}</span>
                      </template>
                    </template></v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" size="20" class="col col-2" v-bind="attrs" v-on="on">
                      mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("tooltips.slotForm.unitOfMeasurement") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex aling-center m-0 mb-5 justify-space-between">
                <div class="col-10">
                  <div class="row d-flex aling-center m-0 justify-space-between">
                    <v-checkbox v-model="lastMeasurementCheck">
                      <template v-slot:label>
                        <span class="mt-3">{{
                          $t("device.slot.slotForm.latest")
                        }}</span>
                      </template></v-checkbox>
                    <v-checkbox v-model="historicalCheck">
                      <template v-slot:label>
                        <span class="mt-3">{{
                          $t("device.slot.slotForm.historical")
                        }}</span>
                      </template></v-checkbox>
                  </div>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" size="20" class="col-2" v-bind="attrs" v-on="on">
                      mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("tooltips.slotForm.displayer") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12" v-if="lastMeasurementCheck">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select item-value="id" item-text="name" :items="instantDisplayModes" label="Instant display mode"
                    :rules="[rules.required]" v-model="form.displayModeId"></v-select>
                </div>
                <div class="col col-1">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" size="20" class="col col-2" v-bind="attrs" v-on="on">
                        mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t("tooltips.instantDisplayMode") }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <dynamic-form ref="lastMeasurementForm" v-show="lastMeasurementCheck && findInstantDisplayMode"
              :inputs="findInstantDisplayMode.layoutConfiguration.inputs" :sendData="dataDisplayMode"
              title="Last Measurement" category="Displayer for last measurement" @response="setInstantDisplayMode"
              :defaultFill="defaultFill"></dynamic-form>
            <div class="col-lg-4 col-12" v-if="historicalCheck">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select item-value="id" item-text="name" :items="historicalDisplayModes"
                    label="Historical display mode" :rules="[rules.required]"
                    v-model="form.historicalDisplayModeId"></v-select>
                </div>
                <div class="col col-1">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" size="20" class="col col-2" v-bind="attrs" v-on="on">
                        mdi-information-outline</v-icon>
                    </template>
                    <span>{{ $t("tooltips.historicalDisplayMode") }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <dynamic-form ref="historicalForm" v-show="historicalCheck && findHistoricalDisplayMode"
              :inputs="findHistoricalDisplayMode.layoutConfiguration.inputs" :sendData="dataHistoricalDisplayMode"
              title="Historical" category="Displayer for historical measurement" @response="setHistoricalDisplayMode"
              :defaultFill="defaultFillHistorical"></dynamic-form>
            <div class="md-layout-item md-size-100 text-right">
              <tgl-save-button ref="saveBtn" :entity="entity" :dispatch="entity.dispatch"
                :action="editMode ? 'editado' : 'creado'" :redirect="entity.redirect" :validateForm="handleSubmit"
                @getForm="getDataFromDynamicForm" class="mb-10"></tgl-save-button>
            </div>
          </div>
        </v-form>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import form from "@/mixins/form";
import DynamicForm from "@/components/Displayers/DynamicForm";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import TGCTextField from "@/components/UI/TGCTextField";
import { mapGetters } from "vuex";
export default {
  name: "slot-form",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  components: {
    DynamicForm,
    TglSaveButton,
    TGCTextField,
  },
  watch: {
    "form.alias"() {
      this.defaultFill.title = this.form.alias;
    },
    "form.variableId"() {
      let unitOfMeasurement = this.unitsOfMeasurement.find(
        (unitOfMeasurement) =>
          unitOfMeasurement.variable_id == this.form.variableId
      );
      this.form.unitOfMeasurementId = unitOfMeasurement.id;

      this.defaultFill.symbol = unitOfMeasurement.symbol;
      this.defaultFill.icon = this.variables.find(
        (variable) => variable.id == this.form.variableId
      ).icons[0];
      this.defaultFill.icons = this.variables.find(
        (variable) => variable.id == this.form.variableId
      ).icons;
    },
    "form.unitOfMeasurementId"() {
      let unitOfMeasurement = this.unitsOfMeasurement.find(
        (unitOfMeasurement) =>
          unitOfMeasurement.id == this.form.unitOfMeasurementId
      );
      this.defaultFill.symbol = unitOfMeasurement.symbol;
    },
    "defaultFill.title"() {
      this.defaultFillHistorical.title = this.defaultFill.title;
    },
    "defaultFill.symbol"() {
      this.defaultFillHistorical.symbol = this.defaultFill.symbol;
    },
  },
  data() {
    return {
      form: {
        alias: "",
        unitOfMeasurementId: 0,
        historicalDisplayModeId: 1,
        displayModeId: 5,
        parameterName: "",
        variableId: 0,
      },
      instantDisplayModes: [],
      historicalDisplayModes: [],
      variables: [],
      unitsOfMeasurement: [],
      editMode: false,
      historicalCheck: false,
      lastMeasurementCheck: false,
      dataDisplayMode: false,
      dataHistoricalDisplayMode: false,
      historical: null,
      lastMeasurement: null,
      defaultFill: {
        title: "",
        symbol: "",
        icon: "",
        icons: [],
      },
      defaultFillHistorical: {
        title: "",
        symbol: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getTheme"]),
    findHistoricalDisplayMode() {
      return this.historicalDisplayModes.find(
        (displayMode) => displayMode.id === this.form.historicalDisplayModeId
      );
    },
    findInstantDisplayMode() {
      return this.instantDisplayModes.find(
        (displayMode) => displayMode.id === this.form.displayModeId
      );
    },
    variableUnitOfMeasurement() {
      return this.unitsOfMeasurement.filter(
        (unitOfMeasurement) =>
          unitOfMeasurement.variable_id == this.form.variableId
      );
    },
    entity() {
      let params = this.editMode
        ? {
          id: this.$route.params.slot,
          alias: this.form.alias.value,
          unitOfMeasurementId: this.form.unitOfMeasurementId.value,
          historicalDisplayModeId: this.form.historicalDisplayModeId.value,
          displayModeId: this.form.displayModeId.value,
          parameterName: this.form.parameterName.value,
          color: this.form.color.value,
        }
        : {
          deviceId: this.$route.params.device,
          requestBody: {
            slot: {
              alias: this.form.alias,
              unitOfMeasurementId: this.form.unitOfMeasurementId,
              parameterName: this.form.parameterName,
            },
            historical: this.historicalCheck ? this.historical : null,
            lastMeasurement: this.lastMeasurementCheck ? this.lastMeasurement : null,
          },
        };
      return {
        name: "slot",
        params: params,
        dispatch: this.editMode ? "slot/editItem" : "slot/createSlot",
        redirect: {
          name: "deviceSlots",
          params: { device: this.$route.params.device },
        },
      };
    },
  },
  mounted: function () {
    if (this.$route.params.slot) {
      this.editMode = true;
      this.getItem();
    }
    this.getDisplayModes();
    this.getVariables();
    this.getUnitsOfMeasurement();
  },
  methods: {
    getDisplayModes() {
      this.$store
        .dispatch("slot/getDisplayModes")
        .then((data) => {
          data.forEach((displayMode) => {
            if (displayMode.isHistorical) {
              this.historicalDisplayModes.push(displayMode);
            } else {
              this.instantDisplayModes.push(displayMode);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVariables() {
      this.$store.dispatch("slot/getVariables").then((data) => {
        this.variables = data;
      });
    },
    getUnitsOfMeasurement() {
      this.$store.dispatch("slot/getUnitsOfMeasurement").then((data) => {
        this.unitsOfMeasurement = data;
      });
    },
    handleSubmit() {
      return this.$refs.form.validate();
    },
    getItem() {
      this.$store
        .dispatch("slot/getItem", {
          device: this.$route.params.device,
          slot: this.$route.params.slot,
        })
        .then((data) => {
          let item = data;
          this.form.alias = item.alias;
          this.form.unitOfMeasurementId = item.unitOfMeasurementId;
          this.form.historicalDisplayModeId = item.historicalDisplayModeId;
          this.form.displayModeId = item.displayModeId;
          this.form.parameterName = item.parameterName;
          this.form.variableId = item.variableId;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToEventListeners() {
      this.$router.push(
        "/devices/edit/" +
        this.$route.params.device +
        "/slots/edit/" +
        this.$route.params.slot +
        "/event-listeners"
      );
    },
    getDataFromDynamicForm() {
      this.dataDisplayMode = true;
      this.dataHistoricalDisplayMode = true;
    },
    setInstantDisplayMode(data) {
      this.lastMeasurement = null;
      this.lastMeasurement = data;
      this.lastMeasurement.displayModeId = this.form.displayModeId;
    },
    setHistoricalDisplayMode(data) {
      this.historical = null;
      this.historical = data;
      this.historical.displayModeId = this.form.historicalDisplayModeId;
    },
  },
};
</script>